.accordion {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 55%; /* Defina a altura como 100% */
}

.accordion-item {
  background-color: #f5f5f5;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #b13636;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.accordion-item.active {
  background-color: #ffffff;
}

.accordion-question {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-answer {
  display: none;
}

.accordion-item.active .accordion-answer {
  display: block;
}
.chevron-icon {
  margin-left: auto; /* Isso alinhará o ícone à direita */
}
